import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import priya from "./priya.png"
import bluebird from "./bluebird.png"
import background from "./background.jpg"
import notebook from "./notebook.png"
// import flowers from "./flowers.png"
import styles from "./image.module.css"

const TestPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const [showWriting, toggleShowWriting] = useState(false)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Art" />
      <h1>👋🏽 Hi, I'm Priya</h1>
      <div className={styles.frame}>
        <img className={styles.background} alt="Background" src={background} />
        <div onMouseEnter={() => { toggleShowWriting(true) }}
          onMouseLeave={() => { toggleShowWriting(false) }}>
          <img className={styles.notebook} alt="notebook" src={notebook} />
        </div>
        <img className={styles.priya} alt="Priya" src={priya} />
        <img className={styles.bluebird} alt="bluebird" src={bluebird} />
        {showWriting ? <p className={styles.about}>Writing</p> : null}
        {/* <img className={imageStyles.flowers} alt="flowers" src={flowers} /> */}
      </div>
    </Layout>
  )
}

export default TestPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
